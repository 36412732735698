

































































































































































































































.dialog-footer {
  display: flex;
  justify-content: space-between;
}
